export default {
    /**
     * Find object in array by key-value pair.
     * @param {string} lookupKey - The property name in the object to read.
     * @param {string} lookupValue - The value to match against the lookupKey property.
     * @param array - The array to search within.
     * @returns {Object} The object in the array or null if nothing is found;
     */
    lookupObject(lookupKey, lookupValue, array) {
        try {
            for (let i = 0; i < array.length; i++) {
                if (array[i][lookupKey] === lookupValue) {
                    return array[i];
                }
            }
        } catch (ex) {
            return null;
        }
        return null;
    },
    /**
     *
     * @param array - Array containing object to be updated
     * @param propertyName - The property name (not value) in which to match with. Primary key property.
     * @param value - The value of the property in which to match against
     * @returns {*} A new array without the object
     */
    removeObject(array, propertyName, value) {
        for (let i = 0; i < array.length; i++) {
            if (array[i][propertyName] === value) {
                array.splice(i, 1);
                break;
            }
        }
        return array;
    },
    /**
     * Update Object In Array
     * @param {Object[]} array - Array containing object to be updated
     * @param {String} idName - The property name (not value) in which to match with. Primary key property.
     * @param {Object} newObject - The full object that is to be replaced
     * @returns {Object[]} A new array containing the updated object
     */
    updateObject(array, idName, newObject) {
        for (let i = 0; i < array.length; i++) {
            if (array[i][idName] === newObject[idName]) {
                for (const prop in array[i]) {
                    array[i][prop] = newObject[prop];
                }
                break;
            }
        }
        return array;
    },
    /**
     *
     * @param array
     * @param idName
     * @param idValue
     * @param valueKey
     * @param newValue
     * @returns {*}
     */
    updateKeyInObject(array, idName, idValue, valueKey, newValue) {
        for (let i = 0; i < array.length; i++) {
            if (array[i][idName] === idValue) {
                for (const prop in array[i]) {
                    if (prop === valueKey) {
                        array[i][prop] = newValue;
                    }
                }
                break;
            }
        }
        return array;
    },
    /**
     * Outputs Initials of the provided string
     * @param {string} value - Input value
     * @returns {string} - Outputs the first letter of each space-separated word in the input.
     */
    getInitials(value): string {
        if (value === '') {
            return '??';
        }
        let initials = value.match(/\b\w/g) || [];
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        return initials;
    },
};
