
import {defineComponent, ref} from "vue";
import {Person} from "@/models/people/Person";
import {PeopleService} from "@/services/PeopleService";
import {ProblemDetails} from "@/models/ProblemDetails";
import {GroupService} from "@/services/GroupService";
import {getMembershipRole, MembershipRole} from "@/models/enums/Role";
import {getMembershipStatus, MembershipStatus} from "@/models/enums/MembershipStatus";
import Swal from "sweetalert2";
import {GroupViewModel} from "@/models/groups/GroupViewModel";
import AddPeopleModal from "@/components/modals/AddPeopleModal.vue";
import {useUser} from "@/store/pinia/userStore";
import {useApplication} from "@/store/pinia/applicationStore";
import {useToast} from "vue-toastification";
import {bus} from "@/modules/eventBus";

export default defineComponent({
  setup() {
    const addPeopleModal = ref<InstanceType<typeof AddPeopleModal>>();
    //Invoke Add People
    const invokeAddPeople = () => {
      addPeopleModal.value?.toggle();
    }
    const userStore = useUser();
    const applicationStore = useApplication();
    const toast = useToast();
    return {addPeopleModal, invokeAddPeople, userStore, applicationStore, toast}
  },
  components: {AddPeopleModal},
  data() {
    return {
      people: [] as Array<Person>,
      groups: [] as Array<GroupViewModel>,
      peopleLoading: true,
    }
  },
  props: ['external'],
  methods: {
    fetch() {
      this.peopleLoading = true
      if (this.isOrganization) {
        PeopleService.fetchOrganization(this.organizationId).then(response => {
          this.people = response.data
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.peopleLoading = false
        })
        if (this.userStore.canManage(this.userStore.currentEntity)) {
          GroupService.fetchOrganization(this.organizationId).then(response => {
            this.groups = response.data
          }).catch(err => {
            let errorDetails = err.response.data as ProblemDetails
            this.toast.error(errorDetails.detail)
          })
        }
      } else {
        PeopleService.fetchProduction(this.productionId).then(response => {
          this.people = response.data
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.peopleLoading = false
        })
        if (this.userStore.canManage(this.userStore.currentEntity)) {
          GroupService.fetchProduction(this.productionId).then(response => {
            this.groups = response.data
          }).catch(err => {
            let errorDetails = err.response.data as ProblemDetails
            this.toast.error(errorDetails.detail)
          })
        }
      }
    },
    getRole(role: MembershipRole) {
      return getMembershipRole(role);
    },
    getStatus(status: MembershipStatus) {
      return getMembershipStatus(status);
    },
    resendInvite(person: Person) {
      this.peopleLoading = true
      if (person.pronouns === 'EXTERNAL') {
        //This is an external invitation
        PeopleService.resendInvitationEmail({
          externalInvitationId: person.accountId,
          organizationId: this.organizationId,
          productionId: this.productionId
        }).then(() => {
          this.toast.success('Invitation email has been resent.')
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.peopleLoading = false
        })
      } else {
        PeopleService.resendInvitationEmail({
          accountId: person.accountId,
          organizationId: this.organizationId,
          productionId: this.productionId
        }).then(() => {
          this.toast.success('Invitation email has been resent.')
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.peopleLoading = false
        })
      }
    },
    rescindInvite(person: Person) {
      Swal.fire({
        title: `Are you sure you want to rescind this invite?`,
        confirmButtonText: 'Rescind',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancel'
      }).then(response => {
        if (response.isConfirmed) {
          this.peopleLoading = true
          if (person.pronouns === 'EXTERNAL') {
            //This is an external invitation
            PeopleService.rescindInvitation({
              externalInvitationId: person.accountId,
              organizationId: this.organizationId,
              productionId: this.productionId
            }).then(() => {
              Swal.fire('Invitation has been rescinded.')
              this.fetch()
            }).catch(err => {
              let errorDetails = err.response.data as ProblemDetails
              this.toast.error(errorDetails.detail)
            })
          } else {
            PeopleService.rescindInvitation({
              accountId: person.accountId,
              organizationId: this.organizationId,
              productionId: this.productionId
            }).then(() => {
              Swal.fire('Invitation has been rescinded.')
              this.fetch()
            }).catch(err => {
              let errorDetails = err.response.data as ProblemDetails
              this.toast.error(errorDetails.detail)
            })
          }
        }
      })
    },
  },
  mounted() {
    this.fetch()
    bus.on('modals:people:invoke', () => {
      this.invokeAddPeople()
    })
  },
  computed: {
    activePeople(): Array<Person> {
      return this.people.filter(person => person.status === MembershipStatus.Active)
    },
    pendingRequests(): Array<Person> {
      return this.people.filter(person => person.status === MembershipStatus.PendingEntity)
    },
    pendingInvitations(): Array<Person> {
      return this.people.filter(person => person.status === MembershipStatus.PendingUser)
    },
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      if (this.isOrganization) {
        return '00000000-0000-0000-0000-000000000000'
      }
      return this.$route.params['productionId'] as string
    },
    emptyBlurb(): string {
      if (this.isOrganization) {
        return 'It doesn\'t looks like you\'ve invited anyone else to join your organization, yet.'
      } else {
        return 'It doesn\'t looks like you\'ve invited anyone else to join your production, yet.'
      }
    }
  }
})
