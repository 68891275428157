
import {defineComponent, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import Button from "@/components/UI/Button.vue";
import {AddPeopleCommand, AddPersonItem} from "@/models/people/AddPeopleCommand";
import InputGroup from "@/components/UI/InputGroup.vue";
import Selector2 from "@/components/inputs/SSelect2.vue";
import {PeopleService} from "@/services/PeopleService";
import {bus} from "@/modules/eventBus";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import {MembershipRole} from "@/models/enums/Role";
import {useToast} from "vue-toastification";
import {useUser} from "@/store/pinia/userStore";
import {useApplication} from "@/store/pinia/applicationStore";
import {WebAnalytics} from "@/modules/webAnalytics";

export default defineComponent({
    setup(_, {emit}) {
        const modal = ref<InstanceType<typeof ModalBase>>();
        const peopleToAdd = ref<Array<AddPersonItem>>([])
        const toggle = () => {
            peopleToAdd.value = []
            modal.value?.toggle()
        }
        const emitAdded = () => {
            emit('added')
            bus.emit('onboarding:production:fetch')
            Swal.fire('Great! Your invitations have been emailed to all valid users.')
            modal.value?.toggle();
        }
        const toast = useToast();
        return {
            modal,
            toggle,
            emitAdded,
            peopleToAdd,
            v$: useVuelidate(),
            toast,
            user: useUser(),
            applicationStore: useApplication()
        }
    },
    components: {Selector2, InputGroup, Button, ModalBase},
    emits: ['added'],
    mounted() {
        bus.on('modal:people:add', () => {
            this.toggle()
        })
    },
    data() {
        return {
            personModel: {
                role: MembershipRole.Member
            } as AddPersonItem,
            roleOptionsOld: [
                {
                    text: 'Member',
                    id: 3
                },
                {
                    text: 'Manager',
                    id: 2
                },
                {
                    text: 'Administrator',
                    id: 1
                }
            ],
            isLoading: false,
            showAddMobile: true
        }
    },
    validations() {
        return {
            personModel: {
                lookupValue: {required},
                title: {required},
                role: {required}
            }
        }
    },
    methods: {
        addPerson() {
            this.v$.personModel.$touch()
            if (!this.v$.personModel.$invalid) {
                this.peopleToAdd.unshift(this.personModel)
                this.personModel = {role: MembershipRole.Member} as AddPersonItem
                this.v$.personModel.$reset();
                this.hideAddMode()
            }
        },
        showAddMode() {
            this.showAddMobile = true
        },
        hideAddMode() {
            this.showAddMobile = false
        },
        removePerson(lookup: string) {
            console.log(lookup);
        },
        submit() {
            if (this.peopleToAdd.length > 0) {
                this.isLoading = true
                PeopleService.add({
                    organizationId: this.organizationId,
                    people: this.peopleToAdd,
                    productionId: this.productionId
                } as AddPeopleCommand).then(() => {
                    this.emitAdded()
                }).catch(() => {
                    this.toast.error('An error occurred while adding one or more people.')
                }).finally(() => {
                    this.isLoading = false
                    if (this.isOrganization) {
                        WebAnalytics.trackFlexible('Added People', {
                            organizationId: this.organizationId,
                            totalCount: this.peopleToAdd.length,
                            membersCount: this.peopleToAdd.filter(x => x.role === MembershipRole.Member).length,
                            managerCount: this.peopleToAdd.filter(x => x.role === MembershipRole.Manager).length,
                            administratorsCount: this.peopleToAdd.filter(x => x.role === MembershipRole.Administrator).length,
                        })
                    } else {
                        WebAnalytics.trackFlexible('Added People', {
                            productionId: this.productionId,
                            totalCount: this.peopleToAdd.length,
                            membersCount: this.peopleToAdd.filter(x => x.role === MembershipRole.Member).length,
                            managerCount: this.peopleToAdd.filter(x => x.role === MembershipRole.Manager).length,
                            administratorsCount: this.peopleToAdd.filter(x => x.role === MembershipRole.Administrator).length,
                        })
                    }
                })
            }
        },
        navigateToPendingList() {
            if ((this.$route.name as string).indexOf('onboard') === -1) {
                if (this.isOrganization) {
                    this.applicationStore.changePeopleTab('requests')
                    this.$router.push({name: 'organization-overview', params: {organizationId: this.organizationId}})
                } else {
                    this.$router.push({name: 'production-overview', params: {productionId: this.productionId}})
                }
            }
        }
    },
    computed: {
        isOrganization(): boolean {
            return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
        },
        organizationId(): string {
            return this.$route.params['organizationId'] as string
        },
        productionId(): string {
            return this.$route.params['productionId'] as string
        },
        stageCode(): string {
            if (this.isOrganization) {
                return this.user.organizations.find(x => x.organizationId === this.organizationId)?.stageCode || ''
            } else {
                return this.user.productions.find(x => x.productionId === this.productionId)?.stageCode || ''
            }
        },
        titleLabel(): string {
            return this.isOrganization ? 'Title' : 'Title/Character';
        },
        roleOptions(): Array<any> {
            if (this.isOrganization) {
                return [
                    {
                        text: 'Manager',
                        id: 2
                    },
                    {
                        text: 'Administrator',
                        id: 1
                    }
                ]
            } else {
                return [
                    {
                        text: 'Member',
                        id: 3
                    },
                    {
                        text: 'Manager',
                        id: 2
                    },
                    {
                        text: 'Administrator',
                        id: 1
                    }
                ]
            }
        },
        roleTooltip(): string {
            if (this.isOrganization) {
                return 'Managers of your organization will automatically gain access to all productions. Only administrators can create new productions.'
            } else {
                return 'Assign your regular cast & crew to the Member role; Managers can send communications, schedule calls, and access members\' personal info.'
            }
        }
    }
})
