import {AxiosResponse} from "axios";
import {apiClient} from "@/modules/apiClient";
import {PersonDetailed} from "@/models/people/PersonDetailed";
import {Person} from "@/models/people/Person";
import {AddPeopleCommand} from "@/models/people/AddPeopleCommand";
import {UpdatePersonCommand} from "@/models/people/UpdatePersonCommand";
import {RemovePersonCommand} from "@/models/people/RemovePersonCommand";
import {RespondToMembershipRequestCommand} from "@/models/people/RespondToMembershipRequestCommand";
import {ResendInvitationCommand} from "@/models/people/ResendInvitationCommand";
import {RescindInvitationCommand} from "@/models/people/RescindInvitationCommand";
import {UpdatePersonGroupsCommand} from "@/models/people/ManageGroupPeopleCommand";
import {GroupViewModel} from "@/models/groups/GroupViewModel";

export const PeopleService = {
    fetchOrganization(organizationId: string): Promise<AxiosResponse<Array<Person>>>{
        return apiClient.get(`/organizations/${organizationId}/people`)
    },
    fetchOrganizationQuery: async (organizationId: string) => {
        const response = await apiClient.get<Array<Person>>(`/organizations/${organizationId}/people`);
        return response.data
    },
    fetchProduction(productionId: string): Promise<AxiosResponse<Array<Person>>>{
        return apiClient.get(`/productions/${productionId}/people`)
    },
    fetchProductionQuery: async (productionId: string) => {
        const response = await apiClient.get<Array<Person>>(`/productions/${productionId}/people`);
        return response.data
    },
    exportProductionContacts(productionId: string, format: string, groups: Array<string>): Promise<AxiosResponse>{
        return apiClient.get(`/productions/${productionId}/people/export?format=${format}`, {responseType: 'blob', params: {groups: groups}})
    },
    exportOrganizationContacts(organizationId: string, format: string, groups: Array<string>): Promise<AxiosResponse>{
        return apiClient.get(`/organizations/${organizationId}/people/export?format=${format}`, {responseType: 'blob', params: {groups: groups}})
    },
    fetchOrganizationPerson(organizationId: string, accountId: string): Promise<AxiosResponse<PersonDetailed>>{
        return apiClient.get(`/organizations/${organizationId}/people/${accountId}`)
    },
    fetchProductionPerson(productionId: string, accountId: string): Promise<AxiosResponse<PersonDetailed>>{
        return apiClient.get(`/productions/${productionId}/people/${accountId}`)
    },
    responseToRequest(command: RespondToMembershipRequestCommand): Promise<AxiosResponse>{
        return apiClient.put('/people/requests', command)
    },
    resendInvitationEmail(command: ResendInvitationCommand): Promise<AxiosResponse>{
        return apiClient.put('/people/invitations/resend', command)
    },
    rescindInvitation(command: RescindInvitationCommand): Promise<AxiosResponse>{
        return apiClient.put('/people/invitations/rescind', command)
    },
    add(command: AddPeopleCommand): Promise<AxiosResponse>{
        return apiClient.post('/people', command)
    },
    update(command: UpdatePersonCommand): Promise<AxiosResponse>{
        return apiClient.put(`/people`, command)
    },
    remove(command: RemovePersonCommand): Promise<AxiosResponse>{
        return apiClient.put(`/people/remove`, command)
    },
    updateGroups(command: UpdatePersonGroupsCommand): Promise<AxiosResponse>{
        return apiClient.put(`/people/groups`, command)
    },
}
