import {AxiosResponse} from "axios";
import {apiClient} from "@/modules/apiClient";
import {Venue} from "@/models/venues/Venue";
import {AddVenueCommand} from "@/models/venues/VenueCommand";
import {VenueSpace} from "@/models/venues/VenueSpace";
import {AddVenueSpaceCommand} from "@/models/venues/AddVenueSpaceCommand";

export const VenueService = {
    fetchOrganization(organizationId: string): Promise<AxiosResponse<Array<Venue>>>{
        return apiClient.get(`/organizations/${organizationId}/venues`)
    },
    fetchProduction(productionId: string): Promise<AxiosResponse<Array<Venue>>>{
        return apiClient.get(`/productions/${productionId}/venues`)
    },
    add(command: AddVenueCommand): Promise<AxiosResponse<Venue>>{
        return apiClient.post('/venues', command)
    },
    update(command: Venue): Promise<AxiosResponse>{
        return apiClient.put(`/venues/${command.venueId}`, command)
    },
    remove(venueId: string): Promise<AxiosResponse>{
        return apiClient.delete(`/venues/${venueId}`)
    }
}

export const VenueSpaceService = {
    add(command: AddVenueSpaceCommand): Promise<AxiosResponse<VenueSpace>>{
        return apiClient.post(`/venues/${command.venueId}/spaces`, command)
    },
    update(command: VenueSpace): Promise<AxiosResponse>{
        return apiClient.put(`/venues/${command.venueId}/spaces/${command.venueSpaceId}`, command)
    },
    remove(venueId: string, venueSpaceId: string): Promise<AxiosResponse>{
        return apiClient.delete(`/venues/${venueId}/spaces/${venueSpaceId}`)
    }
}
