
import {defineComponent, onMounted, ref} from "vue";
import {Modal} from "bootstrap";

export default defineComponent({
  name: "ModalBase",
  setup() {
    const modalElement = ref<Element>();
    onMounted(() => {
      modalElement.value;
    });

    return {
      modalElement,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
    },
    hasHeader: {
      type: Boolean,
      required: false,
      defaultValue: true,
    },
    hasFooter: {
      type: Boolean,
      required: false,
      defaultValue: true,
    },
    hasToolbar: {
      type: Boolean,
      required: false,
      defaultValue: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      defaultValue: false
    },
    loadingText: {
      type: String,
      required: false,
      defaultValue: "Please Wait"
    }
  },
  data: function () {
    return {
      modal: {} as Modal,
      isOpen: false
    };
  },
  methods: {
    toggle() {
      if (this.isOpen) {
        this.isOpen = false
        this.modal.hide();
      } else {
        this.isOpen = true
        this.modal.show();
      }
    },
    toggleOpen() {
      this.isOpen = true
      this.modal.show();
    },
    toggleClosed() {
      this.isOpen = false
      this.modal.hide();
    }
  },
  mounted() {
    if (this.modalElement) {
      this.modal = new Modal(this.modalElement as Element);
    }
  },
  computed: {
    modalBodyClasses() {
      if (this.hasToolbar) {
        // return 'pt-20 pb-20 px-lg-17'
        // return 'pt-10 px-lg-17'
        return ''
      } else {
        // return 'pt-0 pb-20 px-lg-17'
        // return 'pt-10 px-lg-17'
        return ''
      }
    }
  }
});
