
import {defineComponent, ref} from "vue";
import Button from "@/components/UI/Button.vue";
import {useUser} from "@/store/pinia/userStore";
import {OrganizationMembership} from "@/models/organizations/OrganizationMembership";
import VenueSettings from "@/components/SelfContained/VenueSettings.vue";
import PeopleSettings from "@/components/SelfContained/PeopleSettings.vue";
import {UploadFile, UploadProgressEvent, UploadRawFile} from "element-plus/es/components/upload/src/upload";
import {ElMessage} from "element-plus";
import {OrganizationService} from "@/services/OrganizationService";
import {bus} from "@/modules/eventBus";
import {Production} from "@/models/productions/Production";
import {WebAnalytics} from "@/modules/webAnalytics";

export default defineComponent({
  props: ['organizationId'],
  components: {VenueSettings, PeopleSettings, Button},
  setup() {
    const userStore = useUser()
    const imageUrl = ref('')
    const handleAvatarSuccess = (res: UploadProgressEvent, file: UploadFile) => {
      imageUrl.value = URL.createObjectURL(file.raw)
    }
    const beforeAvatarUpload = (file: UploadRawFile) => {
      const isJPG = file.type.indexOf('image') > -1
      const isLt2M = file.size / 1024 / 1024 < 8

      if (!isJPG) {
        ElMessage.error('Logo must be an image')
      }
      if (!isLt2M) {
        ElMessage.error('Logo size can not exceed 8MB')
      }
      return isJPG && isLt2M
    }
    return {userStore, imageUrl, handleAvatarSuccess, beforeAvatarUpload}
  },
  data() {
    return {
      subStep: 0,
      photoIsLoading: false,
      hasAvatarQueued: false,
      photoStepComplete: false,
      onboardingIsLoading: true,
      onboardStatus: {photo: '', hasGroups: false, hasPeople: false, countCallTypes: 2, hasVenues: false} as any,
      productions: [] as Array<Production>
    }
  },
  mounted() {
    this.fetchOnboardStatus()
    bus.on('modals:cropper:handle:organization-onboard', (blob) => {
      this.uploadPhoto(blob)
    })
  },
  methods: {
    tab(subStep: number) {
      this.subStep = subStep
      bus.emit('modals:production:fetch')
    },
    fetchOnboardStatus() {
      OrganizationService.fetchOnboardStatus(this.organizationId).then(response => {
        this.onboardStatus = response.data
        this.onboardingIsLoading = false
        // bus.emit('modals:production:fetch')
      })
      OrganizationService.listProductions(this.organizationId).then(response => {
        this.productions = response.data
      })
    },
    returnToOnboardHome() {
      this.$router.push({name: 'onboarding'})
    },
    goToOrganization() {
      if (this.isFromFlow) {
        this.$router.push({name: 'onboarding', query: {orgStatus: this.completed ? 'completed' : 'skip'}})
      } else {
        this.$log.debug('this.organizationId', this.organizationId)
        this.$router.push({name: 'organization-overview', params: {organizationId: this.organizationId}})
      }
    },
    skip() {
      WebAnalytics.trackFlexible('Skipped Onboarding', {entityType: 'Organization', progress: this.progress})
      this.goToOrganization()
    },
    finish() {
      WebAnalytics.trackFlexible('Finished Onboarding', {entityType: 'Organization'})
      this.goToOrganization()
    },
    requestCropper(xhr) {
      bus.emit('modals:cropper:open', {
        blob: xhr.file,
        requester: 'modals:cropper:handle:organization-onboard',
        isUserPhoto: false
      })
    },
    uploadPhoto(blob) {
      this.photoIsLoading = true
      const formData = new FormData();
      formData.append('file', blob);
      OrganizationService.uploadPhoto(this.organizationId, formData).then(() => {
        this.hasAvatarQueued = false
        this.userStore.fetchOrganizationMemberships()
        this.photoStepComplete = true
      }).finally(() => {
        this.photoIsLoading = false
        this.fetchOnboardStatus()
      })
    },
    addProduction() {
      bus.emit('modals:production:invoke', this.organization.organizationId)
    },
    navigateToProductionOnboarding(productionId: string) {
      this.$router.push({
        name: 'production-onboarding',
        params: {productionId: productionId},
        query: {fromFlow: 'true'}
      })
    },
    addVenue() {
      bus.emit('modals:venue:add')
    },
    addPeople() {
      bus.emit('modals:people:invoke')
    }
  },
  computed: {
    organization(): OrganizationMembership {
      return this.userStore.organizations.filter(p => p.organizationId === this.organizationId)[0]
    },
    orgName(): string {
      if (this.organization) {
        return this.organization.name
      } else {
        return ''
      }
    },
    isFromFlow(): boolean {
      return !!this.$route.query.fromFlow
    },
    completed(): boolean {
      return (this.onboardStatus.hasGroups && this.onboardStatus.hasPeople && this.onboardStatus.hasVenues) &&
          (this.onboardStatus.photo !== '' || this.onboardStatus.photo !== null)
    },
    progress(){
      let totalCompleted = 1

      if (this.onboardStatus.hasGroups) {
        totalCompleted += 1
      }
      if (this.onboardStatus.hasPeople) {
        totalCompleted += 1
      }
      if (this.onboardStatus.hasVenues) {
        totalCompleted += 1
      }
      if (this.onboardStatus.photo !== '' && this.onboardStatus.photo) {
        totalCompleted += 1
      }

      const total = 5
      return (totalCompleted / total) * 100
    }
  }
})
