import {AxiosResponse} from "axios";
import {apiClient} from "@/modules/apiClient";
import {GroupViewModel} from "@/models/groups/GroupViewModel";
import {GroupCommand} from "@/models/groups/GroupCommand";

export const GroupService = {
    fetchOrganization(organizationId: string): Promise<AxiosResponse<Array<GroupViewModel>>> {
        return apiClient.get(`/organizations/${organizationId}/groups`)
    },
    fetchOrganizationQuery: async (organizationId: string) => {
        const response = await apiClient.get<Array<GroupViewModel>>(`/organizations/${organizationId}/groups`);
        return response.data
    },
    fetchProduction(productionId: string): Promise<AxiosResponse<Array<GroupViewModel>>> {
        return apiClient.get(`/productions/${productionId}/groups`)
    },
    fetchProductionQuery: async (productionId: string) => {
        const response = await apiClient.get<Array<GroupViewModel>>(`/productions/${productionId}/groups`);
        return response.data
    },
    addGroup(command: GroupCommand): Promise<AxiosResponse<GroupViewModel>> {
        return apiClient.post(`/groups`, command)
    },
    updateGroup(groupId: string, command: GroupCommand): Promise<AxiosResponse> {
        return apiClient.put(`/groups/${groupId}`, command)
    },
    removeGroup(groupId: string): Promise<AxiosResponse> {
        return apiClient.delete(`/groups/${groupId}`)
    }
}
