
import {defineComponent} from "vue";
import {Venue} from "@/models/venues/Venue";
import {VenueService} from "@/services/VenueService";
import {bus} from "@/modules/eventBus";
import Utilities from "@/utilities/Utilities";

export default defineComponent({
  setup() {
    return {}
  },
  props: ['external'],
  data() {
    return {
      loading: true,
      venues: [] as Array<Venue>
    }
  },
  mounted() {
    bus.on('modals:venue:refetch', () => {
      //console.log('refetch venues')
      this.fetch()
    })
    bus.on('modals:venue:removed', (venueId) => {
      this.handleVenueRemoved(venueId as string)
    })
    bus.on('modals:production:fetch', () => {
      this.fetch()
    })
    this.fetch()
  },
  methods: {
    fetch() {
      this.loading = true
      if (this.isOrganization) {
        VenueService.fetchOrganization(this.organizationId).then(response => {
          this.venues = response.data
          this.loading = false
        })
      } else {
        VenueService.fetchProduction(this.productionId).then(response => {
          this.venues = response.data
          this.loading = false
        })
      }
    },
    addVenue() {
      bus.emit('modals:venue:add')
    },
    updateVenue(venue: Venue) {
      if ((venue.discriminator === 'OrganizationVenue' && this.isOrganization) || (venue.discriminator === 'ProductionVenue' && !this.isOrganization)) {
        bus.emit('modals:venue:update', venue)
      }
    },
    handleVenueAdded(venue: Venue) {
      this.venues.push(venue)
    },
    handleVenueRemoved(venueId: string) {
      Utilities.removeObject(this.venues, 'venueId', venueId)
    }
  },
  computed: {
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      return this.$route.params['productionId'] as string
    }
  }
})
