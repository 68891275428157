
import {defineComponent} from "vue";
import {Skeletor} from 'vue-skeletor';

export default defineComponent({
  components: {Skeletor},
  props: {
    label: {
      type: String,
      required: true
    },
    actionLabel: {
      type: String,
      required: false
    },
    tooltip: {
      type: String,
      required: false
    },
    isRequired: {
      type: Boolean,
      required: false
    },
    isLoading: {
      type: Boolean,
      required: false
    }
  },
  emits: ['actionClick'],
  methods: {
    emitActionClick() {
      this.$emit('actionClick')
    }
  }
})
